import request from '@/utils/request';
import { QuotationProductType } from '@/utils/pb';

// get underlying list
export function getUnderlyings() {
  const req = request.create('GetProductListReq', {
    productType: QuotationProductType.QUOTATION_PRODUCT_TYPE_UNDERLYING,
  });
  return request({
    url: '/miniprogram/getUnderlyingList',
    responseType: 'GetProductListResp',
    requestBody: req,
  });
}

// 询价发行方ISSUER
export async function getQuotationIssuers() {
  return await request({
    url: '/transaction/config/listIssuerQuotation',
    responseType: 'transaction.TransactionIssuerQuotationResponseList',
  });
}

// get popular quotation list
export async function getPopularList(params = {}) {
  const req = request.create('GetRecommendQuotationParamListReq', params);
  return await request({
    url: '/quotationManage/query/getRecommendQuotationRecordList',
    requestBody: req,
    responseType: 'GetQuotationRecommendRecordListResp',
  });
}

// get popular quotation details
export async function getPopularDetail(params = {}) {
  const req = request.create('GetRecommendQuotationInfoReq', params);
  return await request({
    url: '/quotationManage/query/getRecommendQuotationDetail',
    requestBody: req,
    responseType: 'QuotationRecommendDetailResponse',
  });
}

// 根据quotationId + index 获取指定index的询价参数
// id必须满足以下格式,否则将抛出参数异常错误
// quotationId + '-' + {recordIndex} + '-' + issuerId
// eg : 101202110281-0-1
export async function getPopularIndexedRecord(params = {}) {
  const req = request.create('QuotationIndexedRecordReq', params);
  return await request({
    url: '/quotationManage/query/getIndexedRecord',
    requestBody: req,
    responseType: 'QuotationIndexedRecordResp',
  });
}

// get quotation details
export async function getQuotationDetail(params = {}) {
  const req = request.create('GetQuotationInfoReq', params);
  return await request({
    url: '/quotationManage/query/getQuotationInfo',
    requestBody: req,
    responseType: 'GetQuotationInfoResp',
  });
}

// get shared quotation details
export async function getSharedQuotationDetail(params = {}) {
  const req = request.create('QuotationSearchShareRequest', params);
  return await request({
    url: '/quotation/search/shareQuotation',
    requestBody: req,
    responseType: 'QuotationSearchShareResponse',
  });
}

// get shared quotation details (without signin)
export async function getSharedQuotationDetailNoSign(params = {}) {
  const req = request.create('QuotationShareMiniProgramReq', params);
  return await request({
    url: '/miniprogram/getShareDetail',
    requestBody: req,
    responseType: 'QuotationShareMiniProgramDetail',
  });
}

// quotation list
export async function getQuotationList(params) {
  const req = request.create('QuotationSearchListRequest', params);
  return await request({
    url: '/quotation/search/listQuotation',
    requestBody: req,
    responseType: 'QuotationSearchListResponse',
  });
}

// create quotation
export async function createQuotation(params = {}) {
  const req = request.create('CreateQuotationReq', params);
  return await request({
    url: '/quotationManage/update/createQuotation',
    requestBody: req,
    responseType: 'CreateQuotationResp',
  });
}

// fav underlyings
export async function favUnderlyings(params = {}) {
  const req = request.create('QuotationUnderlyingEnshrineRequest', params);
  return await request({
    url: '/quotationManage/underlying/enshrine',
    requestBody: req,
    responseType: 'QuotationUnderlyingEnshrineResponse',
  });
}

// delete fav underlyings
export async function deleteFavUnderlyings(params = {}) {
  const req = request.create(
    'QuotationUnderlyingEnshrineCancelRequest',
    params
  );
  return await request({
    url: '/quotationManage/underlying/enshrine/cancel',
    requestBody: req,
    responseType: 'QuotationUnderlyingEnshrineResponse',
  });
}

// get fav underlyings
export async function getFavUnderlyings(params = {}) {
  const req = request.create('QuotationUnderlyingEnshrineQueryRequest', params);
  return await request({
    url: '/quotationManage/underlying/enshrine/query',
    requestBody: req,
    responseType: 'QuotationUnderlyingEnshrineQueryResponse',
  });
}

// individual scratch issuer
export async function scratchIssuer(params = {}) {
  const req = request.create('QuotationScratchSaveIssuerRequest', params);
  return await request({
    url: '/quotationManage/scratch/saveIssuer',
    requestBody: req,
    responseType: 'QuotationScratchSaveIssuerResponse',
  });
}

// get scratched issuers
export async function getScratchedIssuer(params = {}) {
  const req = request.create('QuotationScratchQueryIssuerRequest', params);
  return await request({
    url: '/quotationManage/scratch/queryIssuer',
    requestBody: req,
    responseType: 'QuotationScratchQueryIssuerResponse',
  });
}

// 询价历史
export async function getPriceHistory(params) {
  const req = request.create('dp.QuotationReq', params);
  return await request({
    url: '/dataplatform/quotation/fetch',
    requestBody: req,
    responseType: 'dp.QuotationRsp',
  });
}

// 历史价格趋势
export async function getPriceTendency(params) {
  const req = request.create('dp.QuotationTendencyReq', params);
  return await request({
    url: '/dataplatform/quotation/tendency',
    requestBody: req,
    responseType: 'dp.QuotationTendencyRsp',
  });
}

// 询价历史（可切换日周月）
export function fetchKLine(params = {}) {
  const req = request.create('dp.KLineReq', params);
  return request({
    url: '/dataplatform/quotation/kline',
    requestBody: req,
    responseType: 'dp.KLineRsp',
  });
}

// 共享默认值，记住上一次选项
export function getShareTeamCache() {
  return request({
    url: '/quotationManage/cache/getShareTeamCache',
    responseType: 'TeamShareCache',
  });
}

// 标的列表
export async function getSearchProduct(params) {
  const req = request.create('SearchProductReq', params);
  return await request({
    url: '/quotationManage/query/searchProduct',
    requestBody: req,
    responseType: 'SearchProductResp',
  });
}

export function getHistoryFeeType(params = {}) {
  const req = request.create('HistoryInsFeeTypeReq', params);
  return request({
    url: '/institution/institutionManage/getHistoryInsFeeType',
    requestBody: req,
    responseType: 'HistoryInsFeeTypeRes',
  });
}
